body {
  //font-family: 'Luckiest Guy', cursive;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  background: url("/assets/images/body-bg.png") no-repeat;
  background-size: cover;
  .cnt-load-div {
    //background: #13112e;
    min-height: calc(100vh - 70px);
    //padding: 1.5rem 0;
    .basic-layout {
      min-height: calc(100vh - 70px);
      .main-content-load-div {
        display: flex;
        padding: 1rem 0;
        position: relative;
        .main-content {
          width: calc(100% - 227px);
          padding: 1rem 2rem;
          @media (max-width: 991px) {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
  .container {
    @media (min-width: 1400px) {
      max-width: 1320px;
    }
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 102px;
  height: 45px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: #3D3D3D;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: linear-gradient(
    180deg,
    rgba(238, 17, 144, 0.053) 0%,
    rgba(162, 108, 194, 0.1) 100%
  );
  box-shadow: inset 10px 10px 50px rgba(145, 145, 145, 0.1),
    inset -25px -5px 50px rgba(141, 141, 141, 0.1);
  backdrop-filter: blur(49.3333px);
  border-radius: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 33px;
  width: 33px;
  left: 10px;
  bottom: 6px;
  background: #f3ba2f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  // background: #3D3D3D;
  background: linear-gradient(0deg, #fea501 -0.01%, #692705 100.03%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #3d3d3d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(49px);
  -ms-transform: translateX(49px);
  transform: translateX(49px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

///vimal test///

.comingsoon-poup .modal-content {
  background-color: transparent;
  font-family: "Fugaz One", cursive;
  color: #fff;
}

.comingsoon-poup .modal-body {
  background-image: url("../src/assets/images/box-bg.png");
  background-size: 100% 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingsoon-poup .modal-body h5 {
  font-size: 34px;
  font-weight: normal;
  color: #fff;
}

.time-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.time-wrapper h6 {
  font-size: 34px;
  color: #fff;
  padding: 5px 15px;
  /* padding-bottom: 0; */
  margin-bottom: 0;
}

.time-wrapper p {
  font-size: 14px;
  color: #fff;
  padding: 10px;
  margin-bottom: 0;
  background-color: #560dc8;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.time-wrapper .time {
  border: 1px solid #fff;
  border-radius: 15px;
  width: 75px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 576px) {
  .comingsoon-poup .modal-dialog {
    max-width: 540px;
    margin: 1.75rem auto;
  }
}

// TIMER MODAL
.time-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  .inner-time {
    border-radius: 15px;
    border: 3px solid #ffaf21;
    padding: 15px;
    margin: 15px 10px;

    p {
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      color: #ffaf21;
      margin-bottom: 0;
      width: 80px;
    }
  }
}

.modal-dialog.comingsoon-poup {
  max-width: 700px;
}
