.swap-page {
  .swap-title-div {
    background: linear-gradient(180deg, #6723be -0.02%, #17054c 99.88%);
    border-radius: 20px;
    background-clip: padding-box;
    position: relative;
    border: solid 3px transparent;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // flex-wrap: wrap;
    &:before {
      background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
      position: absolute;
      content: "";
      bottom: -3px;
      border-radius: 20px;
      left: -3px;
      right: -3px;
      top: -3px;
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      background: url("/assets/images/botimg.png");
      width: 973px;
      height: 111px;
    }
    img.dog {
      mix-blend-mode: screen;
    }
    h3 {
      font-weight: normal;
      font-size: 36px;
      line-height: 26px;
      text-align: center;
      color: #fea501;
      font-family: "Luckiest Guy", cursive;
      margin-top: 2rem;
      @media (max-width: 767px) {
        margin-bottom: 4rem;
      }
    }
  }
  .swap-liq-div {
    position: relative;
    .refresh-settings-div {
      display: flex;
      position: absolute;
      right: 0;
    }
    ul.nav-tabs {
      border-bottom: 0;
      justify-content: center;
      margin-bottom: 2rem;
      @media (max-width: 575px) {
        justify-content: flex-start;
      }
      li.nav-item {
        a.nav-link {
          font-weight: normal;
          font-size: 24px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
          background: transparent;
          border: 0px;
          font-weight: bold;
          cursor: pointer;
          &.active,
          &:hover {
            color: #fea501;
            transition: all 0.5s;
          }
        }
      }
    }
    .swap-liq-det-div {
      background: linear-gradient(180deg, #6723be -0.02%, #17054c 99.88%);
      border-radius: 20px;
      background-clip: padding-box;
      position: relative;
      border: solid 3px transparent;
      &:before {
        background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
        position: absolute;
        content: "";
        bottom: -3px;
        border-radius: 20px;
        left: -3px;
        right: -3px;
        top: -3px;
        z-index: -1;
      }
      .coin-exchange-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
          color: #fea501;
        }
        span {
          font-weight: bold;
          font-size: 26px;
          line-height: 39px;
          color: #ffffff;
        }
      }
      label {
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        color: #fea501;
      }
      input.form-control {
        background: right;
        border: 0px;
        border-bottom: 1px solid #858585;
        border-radius: 0;
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
        color: #ffffff;
        margin-bottom: 1.5rem;
        &:focus {
          box-shadow: none;
        }
      }
      .slip-div {
        h5 {
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
          color: #fea501;
          margin-bottom: 1rem;
        }
        .slip-val {
          background: #552cbd;
          border-radius: 50px;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          width: 50px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;
          cursor: pointer;
          &.active,
          &:hover {
            background: #fea501;
            transition: all.5s;
          }
        }
      }
    }
    .con-wal-btn {
      background: linear-gradient(180deg, #ff110b 0%, #970d0d 100%);
      border-radius: 10px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      width: 168px;
      height: 50px;
      border: 0;
      display: block;
      margin: 3rem auto;
      &:hover {
        background: linear-gradient(180deg, #970d0d 0%, #ff110b 100%);
        transition: all 0.5s;
      }
    }
  }
  .cur-point {
    cursor: pointer;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }

  ::placeholder {
    color: #fff;
  }
}
@media only screen and (max-width: 992px) {
  .mobile-arrow {
    transform: rotate(90deg);
  }
}
