.side-nav {
  background: url("/assets/images/side-bg.png") no-repeat;
  background-size: cover;
  border-radius: 20px;
  width: 227px;
  height: 442px;
  margin-top: -30px;
  @media (max-width: 991px) {
    width: 0;
    position: absolute;
    top: 16px;
    transition: all 0.5s;
    display: none;
  }
  .sidebar-page {
    .menu-cont {
      .menu-ul {
        padding: 0.5rem;
        margin-bottom: 0;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
          img {
            margin-right: 0.5rem;
          }
          a {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            padding: 1rem 0 1rem 1rem;
            width: 100%;

            &:hover {
              text-decoration: none;
              //color: #6258e3;
              transition: all 0.5s;
              // img{
              //     filter: invert(65%) sepia(95%) saturate(3035%) hue-rotate(231deg) brightness(92%) contrast(94%);
              // }
            }
          }
          &.active {
            //background: linear-gradient(3.61deg, #7A0575 -9.1%, #0C0800 112.49%);
            background: url("/assets/images/selected.png") no-repeat;
            width: 211px;
            height: 49px;
            border-radius: 5px;
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .social-div {
    // position: absolute;
    // bottom: -80px;
    margin-top: 8rem;
    .social-media-icons {
      display: flex;
      a {
        background: linear-gradient(0deg, #f80202 -0.01%, #692705 100.03%);
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        margin-right: 5px;
        i {
          color: #fff;
        }
        &:hover {
          background: linear-gradient(0deg, #fea501 -0.01%, #692705 100.03%);
          transition: all 0.5s;
        }
      }
    }
    p {
      font-size: 12px;
      font-size: 11px;
      line-height: 28px;
      color: #ffffff;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}

.side-nav.fliph {
  width: 227px;
  display: block;
  z-index: 1;
  @media (max-width: 991px) {
    top: 135px;
  }
}
