.home-page{
	.title-div{
		//background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
		background: url('/assets/images/title-bg.png') no-repeat;
		background-size: cover;
		border-radius: 20px;
		background-clip: padding-box;
		position: relative;
		border: solid 3px transparent;
		@media(max-width:991px){
			background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
			border:0px;
		}
		&:before{			
			background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
			position: absolute;
			content: '';			
			bottom: -3px;
			border-radius: 20px;
			left: -3px;
			right: -3px;
			top:-3px;
			z-index: -1;
		}
		// &:after{
		// 	content: '';
		// 	position: absolute;
		// 	right: 0;
		// 	top: 0;
		// 	background:url('/assets/images/dot.png');
		// 	width: 409px;
		// 	height: 211px;
		// }
		img.dog{
			mix-blend-mode: screen;
		}
		.title-cnt{
			h3{
				font-weight: normal;
				font-size: 56px;
				line-height: 56px;
				font-family: 'Luckiest Guy', cursive;
				text-align: center;
				@media(max-width:1199px){
					font-size: 40px;
				}
				span.red{
					color: #F70202;
				}
				span.yellow{
					color: #FEA501;
				}
			}
			p{
				font-weight: normal;
				text-align: center;
				font-size: 17px;
				line-height: 26px;
				text-align: center;
				color: #FFFFFF;
				@media(max-width:1199px){
					font-size: 14px;
				}
			}
		}
	}
	.farm-stake-div{
		background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
		border-radius: 20px;
		background-clip: padding-box;
		position: relative;
		border: solid 3px transparent;
		min-height: 420px;
		margin-bottom: 1.5rem;
		&:before{			
			background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
			position: absolute;
			content: '';			
			bottom: -3px;
			border-radius: 20px;
			left: -3px;
			right: -3px;
			top:-3px;
			z-index: -1;
		}
		h4{
			font-weight: normal;
			font-family: 'Luckiest Guy', cursive;
			font-size: 30px;
			line-height: 30px;
			color: #FFAF21;
			text-align: center;
			@media(max-width: 1199px){
				font-size: 25px;
			}
		}
		.harvest-wallet-div{
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			h5{
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #FEA501;
				margin-bottom: 0;
				@media(max-width: 575px){
					font-size: 16px;
				}
			}
			.rdiv{
				h6{
					font-weight: bold;
					font-size: 30px;
					line-height: 45px;
					color: #FFFFFF;
					margin-bottom: 0;
					@media(max-width: 991px){
						font-size: 25px;
					}
				}
				span{
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #B693FF;
				}
			}			
		}
		.con-wal-btn{
			background: linear-gradient(180deg, #FF110B 0%, #970D0D 100%);
			border-radius: 10px;
			font-weight: bold;
			font-size: 14px;
			line-height: 21px;
			color: #FFFFFF;
			width: 168px;
			height: 50px;
			border: 0;
			display: block;
			margin: auto;
			&:hover{
				background: linear-gradient(180deg, #970D0D 0%, #FF110B 100%);
				transition: all .5s;
			}
		}
	}
	.staked-val-stats-div{
		background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
		border-radius: 20px;
		background-clip: padding-box;
		position: relative;
		border: solid 3px transparent;
		min-height: 213px;
		@media(min-width:992px) and (max-width:1199px){
			min-height: 252px;
		}
		&:before{			
			background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
			position: absolute;
			content: '';			
			bottom: -3px;
			border-radius: 20px;
			left: -3px;
			right: -3px;
			top:-3px;
			z-index: -1;
		}
		h4{
			font-weight: normal;
			font-size: 30px;
			line-height: 30px;
			color: #FFAF21;
			margin-bottom: 1.5rem;
			font-family: 'Luckiest Guy', cursive;
			@media(min-width: 1200px) and (max-width: 1300px){
				font-size: 25px;
			}
		}
		h5{
			font-weight: bold;
			font-size: 30px;
			line-height: 45px;
			color: #FFFFFF;
			margin-bottom: 0;
		}
		p{
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: #FEA501;
			margin-bottom: 0;
		}
		span{
			font-weight: bold;
			font-size: 15px;
			line-height: 22px;
			color: #FFFFFF;
		}
		span.val{
			color: #FEA501;
		}
	}
	.announce-stats-div{
		background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
		border-radius: 20px;
		background-clip: padding-box;
		position: relative;
		border: solid 3px transparent;
		margin-bottom: 1rem;
		min-height: 600px;
		@media(max-width:767px){
			padding: 1rem !important;
		}
		@media(min-width:992px) and (max-width:1199px){
			min-height: 252px;
		}
		&:before{			
			background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
			position: absolute;
			content: '';			
			bottom: -3px;
			border-radius: 20px;
			left: -3px;
			right: -3px;
			top:-3px;
			z-index: -1;
		}
		h4{
			font-weight: normal;
			font-size: 30px;
			line-height: 30px;
			color: #FFAF21;
			margin-bottom: 1.5rem;
			font-family: 'Luckiest Guy', cursive;
			text-align: center;			
			@media(min-width: 1200px) and (max-width: 1300px){
				font-size: 25px;
			}
		}
		.collar-bsc-div{
			display: flex;
			align-items: center;
			p{
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				color: #FFFFFF
			}
			span{
				font-weight: normal;
				font-size: 12px;
				line-height: 18px;
				color: #A7A7A7;
			}
		}
		.det-div-ann{
			margin-left: 5rem;
			margin-top: 1.5rem;
			p{
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				color: #FFFFFF;
				margin-bottom: 0;
			}
			a{
				font-weight: normal;
				font-size: 14px;
				line-height: 21px;
				color: #00B0BB;
				word-break: break-all;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.soon-div{
			background: #2B0761;
			border: 1px solid #1F8CF0;
			box-sizing: border-box;
			border-radius: 19px;
			min-height: 205px;
			p{
				font-weight: normal;
				font-size: 13px;
				line-height: 18px;
				text-align: center;
				color: #FFFFFF;
			}
			h6{
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				text-align: center;
				color: #FFFFFF;
			}
		}
		span{
			font-weight: bold;
			font-size: 15px;
			line-height: 22px;
			color: #FFFFFF;
		}
		span.val{
			color: #FEA501;
		}
	}
}