.news-page{
    background: #6610f2;
    min-height: calc(100vh - 70px);
    .news-heading img{
        width: 20px;
        height: 20px;
        filter: brightness(0) invert(1);
    }
    .news-heading p{
        padding-left: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 0;
    }
    
    .news-heading:hover img{
        filter: invert(0) brightness(1);
    }
    .news-heading:hover p{
        color: #6c1eab;   
    }
    .search-box .input-group-text{
        background-color: rgba(34, 31, 82, 0.8);
        border: 1px solid #302c65;
        border-right: transparent;
    }
    .search-box .form-control{
        background-color: rgba(34, 31, 82, 0.8);
        border: 1px solid #302c65;
        color: white;
        border-left: transparent;
        
    }
    .search-box .input-group-text i{
        color: #7d73f8;
    }
    .search-box .form-control:focus{
        box-shadow: none;
    }
    .b-b{
        border-bottom: 1px solid #424158;
        padding-bottom: 20px;
    }
    .main-heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .main-heading h6{
        font-size: 28px;
        font-weight:600;
        color: white;
    }
    .main-heading p{
        color:#787878;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .main-heading p:hover{
        color: white;
    }
    .main-bg img{
        border-radius: 10px;
    }
    .main-bg p{
        font-size: 14px;
        font-weight: 600;
        color: white;
        line-height: 30px;
    }
    .social i{
        color: white;
        padding-left: 10px;
    }
    .social i:hover{
        color: #6c1eab; 
    }
    .footer p{
        color: white;
        font-size: 14px;
        font-weight: 400;
    }
    .side-box{
        background-color: rgba(34, 31, 82, 0.8);
        padding: 10px;
    }
    .side-heading h5{
        font-size: 16px;
        font-weight:600;
        color: white;
        margin-bottom: 0;
    }
    .side-heading p{
        font-size: 14px;
        color: #d8d8d8;
        margin-bottom: 0;
    }
    .side-heading h6{
        font-size: 14px;
        color: #bb51ff;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .date p{
        font-size: 14px;
        color: #d8d8d8;
        margin-bottom: 0;
    }
    @media(max-width: 991px){
        .img-fit{
            margin-bottom: 1rem;
        }
    }
}