.con-wal-btn {
  background: linear-gradient(
180deg, #FF110B 0%, #970D0D 100%);
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  width: 168px;
  height: 50px;
  border: 0;
}