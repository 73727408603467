.swap-page{
	.swap-title-div{
		background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
		border-radius: 20px;
		background-clip: padding-box;
		position: relative;
		border: solid 3px transparent;
		// display: flex;
		// justify-content: space-between;
		// align-items: center;
		// flex-wrap: wrap;
		&:before{			
			background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
			position: absolute;
			content: '';			
			bottom: -3px;
			border-radius: 20px;
			left: -3px;
			right: -3px;
			top:-3px;
			z-index: -1;
		}
		&:after{
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			background:url('/assets/images/botimg.png');
			width: 973px;
			height: 111px;
		}
		img.dog{
			mix-blend-mode: screen;
		}
		h3{
			font-weight: normal;
			font-size: 36px;
			line-height: 26px;
			text-align: center;
			color: #FEA501;
			font-family: 'Luckiest Guy', cursive;
			margin-top: 2rem;
			// @media(max-width:767px){
			// 	margin-bottom: 4rem;
			// }
		}
		p{
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			text-align: center;
			color: #FFFFFF;
		}
		
	}
	.swap-liq-div{
		position: relative;
		.refresh-settings-div{
			display: flex;
			position: absolute;
			right: 0;			
		}
		ul.nav-tabs{
			border-bottom: 0;
			justify-content: center;
			margin-bottom:2rem;
			@media(max-width:575px){
				justify-content: flex-start;
			}
			li.nav-item{
				a.nav-link{
					font-weight: normal;
					font-size: 24px;
					line-height: 26px;
					text-align: center;
					color: #FFFFFF;
					background: transparent;
					border:0px;
					font-weight: bold;
					cursor: pointer;
					&.active, &:hover{
						color: #FEA501;
						transition: all .5s;
						
					}
				}
			}
		}
		.swap-liq-det-div{
			background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
			border-radius: 20px;
			background-clip: padding-box;
			position: relative;
			border: solid 3px transparent;
			&:before{			
				background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
				position: absolute;
				content: '';			
				bottom: -3px;
				border-radius: 20px;
				left: -3px;
				right: -3px;
				top:-3px;
				z-index: -1;
			}
			.bnb-div{
				position: absolute;
				right: 15px;
				span{
					font-weight: bold;
					font-size: 26px;
					line-height: 39px;
					color: #FFFFFF;
					@media(max-width:575px){
						font-size:20px;
					}
				}
			}
			.coin-exchange-div{
				display: flex;
				justify-content: flex-end;
				align-items: center;				
				span{
					font-weight: bold;
					font-size: 26px;
					line-height: 39px;
					color: #FFFFFF;
				}			
			}
			label{
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #FEA501;
			}
			input.form-control{
				background: right;
				border: 0px;
				border-bottom: 1px solid #858585;
				border-radius: 0;
				font-weight: bold;
				font-size: 30px;
				line-height: 45px;
				color: #FFFFFF;
				margin-bottom: 1.5rem;
				&:focus{
					box-shadow: none;
				}
			}
			.con-wal-btn{
				background: linear-gradient(180deg, #FF110B 0%, #970D0D 100%);
				border-radius: 10px;
				font-weight: bold;
				font-size: 16px;
				line-height: 21px;
				color: #FFFFFF;
				width: 168px;
				height: 50px;
				border: 0;
				display: block;
				margin: 2rem auto;
				&:hover{
					background: linear-gradient(180deg, #970D0D 0%, #FF110B 100%);
					transition: all .5s;
				}
			}
			h4{
				font-weight: bold;
				font-size: 26px;
				line-height: 39px;
				color: #FFFFFF;
				@media(max-width:575px){
					font-size:22px;
					text-align: left;
				}
			}
			p{
				font-weight: normal;
				font-size: 14px;
				line-height: 21px;
				color: #B693FF;
			}	
			h5{
				font-weight: normal;
				font-size: 18px;
				line-height: 27px;
				color: #FFFFFF;
			}
			span{
				font-weight: normal;
				font-size: 18px;
				line-height: 27px;
				color: #B693FF;
			}
			a{
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #F7931A;
				&:hover{
					text-decoration: none;
					transition: all .5s;
					color: #5f3c11;
				}
			}
			.value-div{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				.ldiv{
					display: flex;
					flex-direction: column;
					span{
						font-weight: normal;
						font-size: 18px;
						line-height: 27px;
						color: #B693FF;
						@media(max-width:420px){
							font-size: 15px;
						}
					}
					span.val{
						color: #FEA501;
						font-weight: bold;
					}
				}
			}
		}
		.con-wal-btn{
			background: linear-gradient(180deg, #FF110B 0%, #970D0D 100%);
			border-radius: 10px;
			font-weight: bold;
			font-size: 14px;
			line-height: 21px;
			color: #FFFFFF;
			width: 168px;
			height: 50px;
			border: 0;
			display: block;
			margin: 2rem auto;
			&:hover{
				background: linear-gradient(180deg, #970D0D 0%, #FF110B 100%);
				transition: all .5s;
			}
		}
		
	}
	.remove-liquidity-div{
		.swap-liq-det-div{
			background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
			border-radius: 20px;
			background-clip: padding-box;
			position: relative;
			border: solid 3px transparent;
			&:before{			
				background:  linear-gradient( 90deg , #fa7f03, #febc00, #a05051);
				position: absolute;
				content: '';			
				bottom: -3px;
				border-radius: 20px;
				left: -3px;
				right: -3px;
				top:-3px;
				z-index: -1;
			}
			.amt-det{
				display: flex;
				justify-content: space-between;
				align-items: center;
				span{
					font-weight: bold;
					font-size: 18px;
					line-height: 27px;
					color: #FEA501;
				}
			}
			.remove-progress-div{
				span{
					font-weight: bold;
					font-size: 30px;
					line-height: 45px;
					color: #FFFFFF;
				}
				.RSPBprogressBar{
					height: 18px;
					background-color: #6221B6;
					border-radius: 20px;
					.RSPBprogression{
						background: #DB100B;
					}
				}
			}
			.range-div {
				display: flex;
				flex-wrap: wrap;
				.val{
					width: 86px;
					height: 47px;
					background: #6221B6;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 0.5rem;
					margin-bottom: 0.5rem;
					cursor: pointer;
					&:hover{
						background: #DB100B;
						transition: all .5s;
					}
					span{
						font-weight: normal;
						font-size: 20px;
						line-height: 30px;
						color: #FFFFFF;
					}
				}
			}
			.token-val-div{
				span{
					font-weight: normal;
					font-size: 18px;
					line-height: 44px;
					color: #B693FF;
				}
				span.val{
					font-weight: bold;
					font-size: 20px;
					line-height: 44px;
					color: #FEA501;
				}
			}
			.price-div{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				span{
					font-weight: normal;
					font-size: 18px;
					line-height: 44px;
					color: #B693FF;
				}
				span.val{
					font-weight: bold;
					font-size: 20px;
					line-height: 44px;
					color: #FEA501;
				}
			}
			.btndiv{
				display: flex;
				justify-content: center;
				align-items: center;
				.approve-btn{
					background: linear-gradient(180deg, #FF110B 0%, #970D0D 100%);
					border-radius: 10px;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					color: #FFFFFF;
					border: 0px;
					width: 168px;
					height: 49px;
					&:hover{
						background: linear-gradient(180deg, #970D0D 0%, #FF110B 100%);
						transition: all .5s;
					}
				}
				.remove-btn{
					background: linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%);
					border-radius: 10px;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					color: #FFFFFF;
					border: 0px;
					width: 168px;
					height: 49px;
					&:hover{
						background: linear-gradient(0deg, #692705 -0.01%, #FEA501 100.03%);
						transition: all .5s;
					}
				}
			}
			.token-det-div{
				h3{
					font-weight: bold;
					font-size: 18px;
					line-height: 27px;
					color: #FEA501;
				}
				span{
					font-weight: normal;
					font-size: 20px;
					line-height: 44px;
					color: #FFFFFF
				}
				span.val{
					font-weight: bold;
				}
			}
		}
	}
	.cur-point{
		cursor: pointer;
	}
	::-webkit-input-placeholder { /* Edge */
		color: #fff;
	}
	  
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #fff;
	}
	  
	::placeholder {
	color: #fff;;
	}
}
.settings-modal{
	max-width: 770px;
    .modal-content{
        background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
        background-clip: border-box;
        border-radius: 20px;
        background-clip: padding-box;
        position: relative;
        border: solid 3px transparent;		
        &:after{
            background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
            position: absolute;
            content: '';
            bottom: -3px;
            border-radius: 20px;
            left: -3px;
            right: -3px;
            top: -3px;
            z-index: -1;
        }
        .modal-header{
			border-bottom: 1px solid #9B7DAD;
			padding-bottom: 20px;
            h5{
                font-weight: normal;
                font-size: 30px;
                line-height: 30px;
                color: #FFAF21;
                font-family: 'Luckiest Guy', cursive;
                width: 100%;
                text-align: center;
            }
            .close{
                font-size: 1.5rem;
                opacity: 1;
                color: #fff;
            }
        }
        .modal-body{
			.search-input{
				background: #713ED0;
				box-shadow: inset 10px 10px 50px rgba(145, 145, 145, 0.1), inset -25px -5px 50px rgba(141, 141, 141, 0.1);
				backdrop-filter: blur(49.3333px);
				border-radius: 50px;
				margin-bottom: 1.5rem;
				height: 75px;
				font-weight: normal;
				font-size: 20px;
				line-height: 30px;
				color: #B693FF;
				border-color: transparent;
			}
           h3{
				font-weight: 500;
				font-size: 26px;
				line-height: 39px;
				color: #FFFFFF;
				margin-bottom: 1.5rem;
				@media(max-width: 575px){
					font-size: 20px;
				}
		   }
		   .speed-div{
			   display: flex;
			   justify-content: space-between;
			   align-items: center;
			   margin-bottom: 1.5rem;
			   flex-wrap: wrap;
			   .value{
					background: #713ED0;
					box-shadow: inset 2.46667px -2.46667px 5px rgba(62, 51, 161, 0.1), inset -2.46667px 2.46667px 5px rgba(255, 255, 255, 0.1);
					backdrop-filter: blur(5px);
					border-radius: 50px;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #FFFFFF;
					width:198px;
					height: 55px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					margin-bottom: 0.5rem;
					&:hover{
						background: linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%);
						border-radius: 10px;
						transition: all .5s;
					}
			   }
		   }
		   p{
				font-weight: normal;
				font-size: 21px;
				line-height: 31px;
				color: #B882D8;
				@media(max-width: 575px){
					font-size:18px;
				}
		   }
		   .slippage-div{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 1.5rem;
				.value{
					background: #713ED0;
					box-shadow: inset 2.46667px -2.46667px 5px rgba(62, 51, 161, 0.1), inset -2.46667px 2.46667px 5px rgba(255, 255, 255, 0.1);
					backdrop-filter: blur(5px);
					border-radius: 50px;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #FFFFFF;
					width:128px;
					height: 55px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					margin-bottom: 0.5rem;
					&:hover{
						background: linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%);
						border-radius: 10px;
						transition: all .5s;
					}
				}
		   }
		   .mode-div{
			   .form-control{
					background: linear-gradient(180deg, rgba(238, 17, 144, 0.053) 0%, rgba(162, 108, 194, 0.1) 100%);
					box-shadow: inset 10px 10px 50px rgba(145, 145, 145, 0.1), inset -25px -5px 50px rgba(141, 141, 141, 0.1);
					backdrop-filter: blur(49.3333px);
					border-radius: 50px;
					width: 105px;
					height: 55px;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #FFFFFF;
					border-color: transparent;
			   }
			   span{
					font-weight: normal;
					font-size: 21px;
					line-height: 31px;
					color: #FFFFFF;
					@media(max-width: 575px){
						font-size: 16px;
					}
				}
		   }
		   .recent-trans-table{
			   table{
				   tbody{
					   tr{
						   td{
								font-weight: normal;
								font-size: 26px;
								line-height: 39px;							
								color: #FFFFFF;
								white-space: nowrap;
								border-top:0px;
								border-bottom: 0px;
						   }
						   td.address{
								font-weight: normal;
								font-size: 21px;
								line-height: 31px;							
								color: #B882D8
						   }
					   }
				   }
			   }
		   }
        }
    }
	.cur-point{
		cursor: pointer;
	}
	::-webkit-input-placeholder { /* Edge */
		color: #B693FF;
	}
	  
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #B693FF;
	}
	  
	::placeholder {
	color: #B693FF;;
	}
}
.sel-coin-modal{
	.modal-content{
		background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
        background-clip: border-box;
        border-radius: 20px;
        background-clip: padding-box;
        position: relative;
        border: solid 3px transparent;		
        &:after{
            background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
            position: absolute;
            content: '';
            bottom: -3px;
            border-radius: 20px;
            left: -3px;
            right: -3px;
            top: -3px;
            z-index: -1;
        }
		.modal-header{
			border-bottom: 1px solid #9B7DAD;
			padding-bottom: 20px;
            h5{
                font-weight: normal;
                font-size: 30px;
                line-height: 30px;
                color: #FFAF21;
                font-family: 'Luckiest Guy', cursive;
                width: 100%;
                text-align: center;
            }
            .close{
                font-size: 1.5rem;
                opacity: 1;
                color: #fff;
            }
        }
		.modal-body{
			::-webkit-input-placeholder { /* Edge */
				color: #B693FF;
			}
			  
			:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #B693FF;
			}
			  
			::placeholder {
			color: #B693FF;;
			}
			.search-input{
				background: #713ED0;
				box-shadow: inset 10px 10px 50px rgba(145, 145, 145, 0.1), inset -25px -5px 50px rgba(141, 141, 141, 0.1);
				backdrop-filter: blur(49.3333px);
				border-radius: 50px;
				font-weight: normal;
				font-size: 18px;
				line-height: 30px;
				color: #B693FF;
				height: 60px;
				border-color: transparent;
				margin-bottom: 1.5rem;				
			}
			.coin-list{
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: transparent;
				padding: 0 1rem;
				cursor: pointer;
				span{
					font-weight: normal;
					font-size: 20px;
					line-height: 59px;
					color: #FFFFFF;
				}
				&:hover, &.active{
					background: linear-gradient(3.61deg, #7A0575 -9.1%, #0C0800 112.49%) no-repeat;
					background: url('/assets/images/coinlistbg.png');
					background-size: cover;
					position: relative;
					border-radius: 5px;
					border-right: 1px solid #FFAF21;
					transition: all .5s;			
				}
			}
		}
	}
}