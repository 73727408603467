header.com-head{
    .bg-light{
        background: transparent !important;
        padding: 0;
        .navbar-toggler{
            border-color: transparent;
            .navbar-toggler-icon{
                //background: url('/assets/images/menu-icon1.png');
                width: 46px;
                height: 26px;
            }
        }
        ul.navbar-nav{
            li.nav-item{
                a.nav-link{
                    font-size: 16px;
                    color: #FFA632;
                    line-height: 24px;
                    padding: 0.5rem 1rem;
                    display: flex;
                    align-items: center;
                    .headimg{
                        background: linear-gradient(180deg, #5F23BE -0.02%, #17054C 99.88%);
                        border-radius: 50px;
                        width: 55px;
                        height: 53px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 0.5rem;
                    }
                    @media(max-width: 1199px){
                        padding: 0.5rem 1rem;
                    }
                    &:hover, &.active{
                        &:after{
                            transform: scaleX(1);
                        }
                    }
                    &:after{
                        display:block;
                        content: '';
                        border-bottom: solid 1px #fff;  
                        transform: scaleX(0);  
                        transition: transform 500ms ease-in-out;
                        padding-top: 5px;
                        @media(max-width: 1199px){
                            display: none;
                        }
                    }
                }
            }
        }
        .con-wal-btn{
            background: linear-gradient(180deg, #FF110B 0%, #970D0D 100%);
            border-radius: 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            width: 168px;
            height: 50px;
            border: 0;
            &:hover{
                background: linear-gradient(180deg, #970D0D 0%, #FF110B 100%);
                transition: all .5s;
            }
        }
    }
}
.headimg{
    background: linear-gradient(180deg, #5F23BE -0.02%, #17054C 99.88%);
    border-radius: 50px;
    width: 55px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    cursor: pointer;
}
.navbar-toggler{
    .navbar-toggler-icon{
        background: url('/assets/images/menu-icon.png');
        width: 46px;
        height: 46px;
    }
    &:focus{
        outline: 0;
    }
}
.con-wal-modal{
    .modal-content{
        background: linear-gradient(180deg, #6723BE -0.02%, #17054C 99.88%);
        background-clip: border-box;
        border-radius: 20px;
        background-clip: padding-box;
        position: relative;
        border: solid 3px transparent;
        &:after{
            background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
            position: absolute;
            content: '';
            bottom: -3px;
            border-radius: 20px;
            left: -3px;
            right: -3px;
            top: -3px;
            z-index: -1;
        }
        .modal-header{
            border-bottom: 0;
            h5{
                font-weight: normal;
                font-size: 30px;
                line-height: 30px;
                color: #FFAF21;
                font-family: 'Luckiest Guy', cursive;
                width: 100%;
                text-align: center;
            }
            .close{
                font-size: 1.5rem;
                opacity: 1;
                color: #fff;
            }
        }
        .modal-body{
            .wal-list{
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 1rem;
                &:hover{
                    background: #411da4;
                    border-radius: 10px;
                    transition: all .5s;
                }
                span{
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 45px;
                    color: #FFFFFF;
                    margin-left: 1rem;
                }
            }
        }
    }
}