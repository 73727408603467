.stake-page {
  .swap-title-div {
    background: linear-gradient(180deg, #6723be -0.02%, #17054c 99.88%);
    border-radius: 20px;
    background-clip: padding-box;
    position: relative;
    border: solid 3px transparent;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // flex-wrap: wrap;
    &:before {
      background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
      position: absolute;
      content: "";
      bottom: -3px;
      border-radius: 20px;
      left: -3px;
      right: -3px;
      top: -3px;
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      background: url("/assets/images/botimg.png");
      width: 973px;
      height: 111px;
    }
    img.dog {
      mix-blend-mode: screen;
    }
    h3 {
      font-weight: normal;
      font-size: 35px;
      line-height: 26px;
      text-align: center;
      color: #fea501;
      font-family: "Luckiest Guy", cursive;
      margin-top: 2rem;
      @media (max-width: 767px) {
        margin-bottom: 4rem;
      }
    }
  }
  .swap-liq-div {
    background: linear-gradient(180deg, #6723be -0.02%, #17054c 99.88%);
    border-radius: 20px;
    background-clip: padding-box;
    position: relative;
    border: solid 3px transparent;
    margin-bottom: 1rem;
    &:before {
      background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
      position: absolute;
      content: "";
      bottom: -3px;
      border-radius: 20px;
      left: -3px;
      right: -3px;
      top: -3px;
      z-index: -1;
    }
    .titlediv {
      justify-content: center;
      flex-wrap: wrap;
      img {
        margin-bottom: 0.5rem;
      }
      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
      }
    }
    h5 {
      font-weight: 600;
      font-size: 20px;
      line-height: 31px;
      color: #fea501;
      text-align: center;
    }
    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 31px;
      color: #ffffff;
      text-align: center;
    }
    .con-wal-btn {
      background: linear-gradient(180deg, #ff110b 0%, #970d0d 100%);
      border-radius: 10px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      width: 150px;
      height: 50px;
      border: 0;
      display: block;
      margin: 1rem auto;
      &:hover {
        background: linear-gradient(180deg, #970d0d 0%, #ff110b 100%);
        transition: all 0.5s;
      }
    }
  }
  .cur-point {
    cursor: pointer;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }

  ::placeholder {
    color: #fff;
  }
}

/*--||
04012021
||--*/
.tlMlPwr {
  font-weight: 600;
  font-size: 16px;
  color: #fea501;
}

.stCdR {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2px 0;
}

.stCdLbl {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  line-height: 21px;
}

.stCdVal {
  font-size: 14px;
  color: #fea501;
  font-weight: 600;
  line-height: 21px;
}

.stCdEr {
  padding: 1.375rem 0 0;
}

.stCdErR {
  padding: 6px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.stCdErTx {
  color: #6a4eb1;
  font-size: 17px;
  font-weight: 600;
}

.btn-10742 {
  min-width: 107px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  border: 0px;
}

.btn-Hrv {
  background: #0c0324;
  color: #ffffff;
  pointer-events: none;
  &:hover {
    background: #0c0324;
    color: #ffffff;
  }
}

.stCdEr.active {
  .stCdErTx {
    color: #fea501;
  }

  .btn-Hrv {
    pointer-events: auto;
    background: linear-gradient(0deg, #fea501 -0.01%, #692705 100.03%);
    &:hover {
      background: linear-gradient(90deg, #fea501 -0.01%, #692705 100.03%);
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.sel-coin-modal .modal-content {
  background: linear-gradient(180deg, #6723be -0.02%, #17054c 99.88%);
  background-clip: border-box;
  border-radius: 20px;
  background-clip: padding-box;
  position: relative;
  border: solid 3px transparent;
}
.sel-coin-modal .modal-content:after {
  background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
  position: absolute;
  content: "";
  bottom: -3px;
  border-radius: 20px;
  left: -3px;
  right: -3px;
  top: -3px;
  z-index: -1;
}
.sel-coin-modal .modal-content .modal-header {
  border-bottom: 1px solid #9b7dad;
  padding-bottom: 20px;
}
.sel-coin-modal .modal-content .modal-header h5 {
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  color: #ffaf21;
  font-family: "Luckiest Guy", cursive;
  width: 100%;
  text-align: center;
}
.sel-coin-modal .modal-content .modal-header .close {
  font-size: 1.5rem;
  opacity: 1;
  color: #fff;
}
.sel-coin-modal .modal-content .modal-body ::-webkit-input-placeholder {
  color: #b693ff;
}
.sel-coin-modal .modal-content .modal-body :-ms-input-placeholder {
  color: #b693ff;
}
.sel-coin-modal .modal-content .modal-body ::placeholder {
  color: #b693ff;
}
.sel-coin-modal .modal-content .modal-body .search-input {
  background: #713ed0;
  box-shadow: inset 10px 10px 50px rgba(145, 145, 145, 0.1),
    inset -25px -5px 50px rgba(141, 141, 141, 0.1);
  backdrop-filter: blur(49.3333px);
  border-radius: 50px;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #b693ff;
  height: 60px;
  border-color: transparent;
  margin-bottom: 1.5rem;
}
.sel-coin-modal .modal-content .modal-body .coin-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 0 1rem;
  cursor: pointer;
}
.sel-coin-modal .modal-content .modal-body .coin-list span {
  font-weight: normal;
  font-size: 20px;
  line-height: 59px;
  color: #fff;
}
.sel-coin-modal .modal-content .modal-body .coin-list:hover,
.sel-coin-modal .modal-content .modal-body .coin-list.active {
  background: linear-gradient(3.61deg, #7a0575 -9.1%, #0c0800 112.49%) no-repeat;
  background: url("/assets/images/coinlistbg.png");
  background-size: cover;
  position: relative;
  border-radius: 5px;
  border-right: 1px solid #ffaf21;
  transition: all 0.5s;
}

/*stake popup style*/
.PP-Stk .modal-dialog {
  max-width: 845px;
}
.PP-Stk .modal-content {
  padding: 20px 40px 20px 30px;
}
.PP-Stk .modal-content .modal-header {
  margin-bottom: 35px;
  padding-bottom: 25px;
}
.FB_18fea {
  font-weight: bold;
  font-size: 16px;
  color: #fea501;
}
.FB_18ff {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}
.StkFrm .form-control {
  border-color: transparent;
  border-bottom: 1px solid #858585;
  background: transparent;
  font-weight: bold;
  font-size: 30px;
  color: #fff;
  width: 100%;
  outline: none;
  box-shadow: unset;
}
.StkRbtn {
  background: #2e1265;
  border-radius: 10px;
  width: 90px;
  height: 49px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border: unset;
  margin-right: 10px;
}
.StkRbtn:hover {
  background: linear-gradient(0deg, #fea501 -0.01%, #692705 100.03%);
  border-radius: 5px;
  transition: 0.3s all ease;
  color: #fff;
}

.FB_18cb2 {
  font-weight: bold;
  font-size: 18px;
  color: #cb2a28;
}
.Stk-btn {
  background: linear-gradient(180deg, #ff110b 0%, #970d0d 100%);
  border-radius: 10px;
  width: 100%;
  height: 49px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border: unset;
}
.Stk-btn:hover {
  background: linear-gradient(80deg, #ff110b 0%, #970d0d 100%);
  color: #fff;
}

// Changes (SRI)\
.dog-imwrapper {
  position: relative;
  .go-wra {
    position: relative;
  }

  .coin-imwrapper {
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #ca802e;
      border: 1px solid #ca802e;
      border-radius: 100%;
      padding: 5px;
      width: 40px;
      &:nth-child(1) {
        bottom: 0;
      }
      &:nth-child(2) {
        transform: scale(0.8);
        top: 50%;
        right: -15%;
      }
    }
  }
}
.clr_table_1 {
  background: linear-gradient(180deg, #6723be -0.02%, #17054c 99.88%);
  border-radius: 20px;
  background-clip: padding-box;
  position: relative;
  border: solid 3px transparent;
  margin-bottom: 1rem;
}
.clr_table_1::before {
  background: linear-gradient(90deg, #fa7f03, #febc00, #a05051);
  position: absolute;
  content: "";
  bottom: -3px;
  border-radius: 20px;
  left: -3px;
  right: -3px;
  top: -3px;
  z-index: -1;
}
.tab_head_colr tr th {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
.tab_head_colr tr th {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.tab_body_colr tr td {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
.btn_tab_clr {
  background: linear-gradient(0deg, #fea501 -0.01%, #692705 100.03%);
  padding: 3px 15px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  border: none;
  border-radius: 10px;
}
.btn_tab_clr:focus {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  border: none;
  box-shadow: none;
}
.btn_tab_clr_1 {
  background: linear-gradient(180deg, #ff110b 0%, #970d0d 100%);
}
